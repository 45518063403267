@mixin action-arrows($selector: 'span', $transitionWidth: rem(20)) {
  #{$selector} {
    display: inline-block;
    padding-right: $transitionWidth;
    position: relative;
    transition: all 0.3s ease 0.1s;

    &::before,
    &::after {
      content: url('/public/icons/arrow-long-right-white.svg');
      font-family: 'font-sbb-icons-medium' !important;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-style: normal !important;
      font-variant: normal !important;
      font-weight: normal !important;
      line-height: 1;
      speak: none;
      text-transform: none !important;
    }

    &::before {
      left: -$transitionWidth;
      opacity: 0;
      position: absolute;
      transition: left 0.3s ease 0.1s, opacity 0.3s ease 0.1s;
    }

    &::after {
      opacity: 1;
      position: absolute;
      right: 0;
      transition: right 0.3s ease 0.1s, opacity 0.3s ease 0.1s;
    }
  }

  &:hover #{$selector} {
    padding-left: $transitionWidth;
    padding-right: 0;

    &::before {
      left: 0;
      opacity: 1;
    }

    &::after {
      opacity: 0;
      right: -$transitionWidth;
    }
  }
}
