@use '../../functions' as *;

.sbb-notification {
  animation: grow 0.5s;
  border-radius: rem(2);
  box-shadow: none;
  line-height: 1rem;
  padding: rem(16);

  &.position-fixed {
    z-index: z-index(page-layout, toast);
  }

  &.show {
    bottom: var(--divider-medium);
  }

  .toast-body {
    padding: 0;

    .icon {
      flex-shrink: 0;
      height: rem(24);
      margin-right: var(--divider-thin);
      width: rem(24);
    }
  }

  &.-success {
    background-color: $color-white;
    border: 1px solid $color-green;
    color: $color-green;

    .icon polyline,
    .icon path {
      stroke: $color-green;
    }
  }

  &.-info {
    border: 1px solid $color-granite;
    color: $color-granite;

    .icon path {
      stroke: $color-granite;
    }
  }

  &.-warning {
    background-color: $color-peach;
    color: $color-black;

    .icon path {
      stroke: $color-black;
    }
  }

  &.-error {
    background-color: $color-red;
    color: $color-white;

    .toast-close {
      color: $color-white;
    }

    .icon path {
      stroke: $color-white;
    }
  }

  .toast-close {
    background-color: transparent;
    border: 0;
    padding: 0;
  }
}
@keyframes grow {
  from {
    bottom: rem(-72);
  }

  to {
    bottom: var(--divider-medium);
  }
}
