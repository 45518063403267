@use '../../functions' as *;

.asset-wrapper:hover + .scene-frontify-wrapper {
  transition: 0.3s;
  visibility: visible;
}

.scene-number-wrapper {
  svg {
    cursor: pointer;
  }
}

.scene-frontify-wrapper {
  background: rgba(255, 255, 255, 0.75);
  border: rem(1) solid $color-silver;
  border-radius: rem(4);
  visibility: hidden;
}

.scene-frontify-wrapper:hover {
  visibility: visible;
}

.scene-frontify {
  background: rgba(255, 255, 255, 1);
  height: 75%;
  min-height: rem(208);
  padding: rem(16);
}

img {
  border-radius: rem(4);
  height: auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  width: auto;
}

source {
  border-radius: rem(4);
  height: auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  width: auto;
}

