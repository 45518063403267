@use '../../functions' as *;

.clipboard-button {
  background-color: transparent;
  border: rem(1) solid;
  border-color: $color-graphite $color-graphite $color-white;
  border-top-left-radius: rem(3);
  border-top-right-radius: rem(3);
  margin-bottom: rem(-1);
}
