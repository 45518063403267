@use '../../functions' as *;
$usermenu-height: 1.6rem;

.usermenu {
  align-items: center;
  display: flex;

  &__avatar {
    background: $color-cloud;
    border-radius: 100%;
    contain: none;
    float: left;
    font-size: inherit;
    height: $usermenu-height;
    opacity: 0.9999;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: $usermenu-height;
    will-change: opacity, transform;
  }

  &__initials {
    color: $color-anthracite;
    display: block;
    font-size: 0.625rem;
    font-weight: bold;
    line-height: $usermenu-height;
  }

  .user-icon path {
    stroke: $color-metal;
  }

  .arrow-icon {
    margin-left: rem(8);
    transition: 0.3s ease-in-out;

    polyline {
      stroke: $color-metal;
    }
  }

  &__button {
    border: 1px solid transparent;
    border-bottom: 0;
    padding: rem(8);

    &[aria-expanded='true'] {
      border-color: $color-black;
    }

    &.show {
      .arrow-icon {
        transform: rotate(-180deg);
        transition: 0.3s ease-in-out;
      }
    }
  }

  &__dropdown {
    border-top: 1px solid $color-silver;
    min-width: 100%;

    a .icon {
      stroke: $color-black;
    }

    a:hover .icon {
      stroke: var(--bs-dropdown-link-hover-color);
    }
  }
}
