@use '../../functions' as *;
@import '../../mixins/font-fixes';

:root {
  --sidebar-width-mobile: calc(100vw - 3rem);
  --sidebar-width-tablet: 70vw;
  --sidebar-width-desktop: 25vw;
}

.sidebar .nav-tabs {
  --bs-nav-tabs-border-width: 0;
  --bs-nav-tabs-border-radius: 0;
}

.sidebar {
  height: calc(100vh - var(--header-height));
  position: fixed;
  right: 0;
  top: var(--header-height);
  z-index: z-index(page-layout, sidebar);

  .change-workflow-step-button {
    height: rem(48);
  }

  &__backdrop {
    display: none;
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: -1;
  }

  &.-open &__backdrop {
    display: block;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__toggle {
    background-color: $color-silver;
    height: 100%;
    width: rem(48);

    .nav-tabs .nav-link {
      border-left: rem(2) solid transparent;

      &.active {
        background-color: $color-milk;
        border-left: rem(2) solid $color-granite;
      }

      &:not(.active):hover {
        border-left: rem(2) solid $color-red125;

        path {
          fill: $color-red125;
        }
      }

      &[data-bs-target='#sidebar-information']:not(.active):hover path {
        fill: none;
        stroke: $color-red125;
      }
    }

    button {
      background-color: transparent;
      border: 0;
      padding: rem(8);
      width: 100%;
    }
  }

  &.-open &__toggle .collapse-button svg {
    transform: rotate(-180deg);
    transition: 0.3s ease-in-out;
  }

  &__collapse {
    background-color: $color-milk;
    overflow-y: auto;

    .tab-pane {
      min-height: 100%;
      width: var(--sidebar-width-mobile);

      @include media-breakpoint-up(sm) {
        width: var(--sidebar-width-tablet);
      }

      @include media-breakpoint-up(lg) {
        width: var(--sidebar-width-desktop);
      }
    }
  }

  &__button {
    padding: rem(12) rem(16);
  }

  &-section {
    background-color: $color-milk;
    min-height: calc(100vh - var(--header-height));
    overflow-y: auto;
    padding: rem(32);

    &__title {
      font-size: rem(21);
    }
  }

  &__divider {
    border-bottom: 1px solid $color-silver;
  }

  .sbb-form-field textarea ~ .input-counter {
    margin-bottom: rem(-8);
  }

  .comment-meta {
    color: $color-granite;
    font-size: rem(13);
  }

  .comment-content {
    @include font-fix-ctrl-a-delete;

    .mention-tag {
      color: $color-blue-sky;
      text-decoration: underline;
    }
  }

  .watchers {
    button > span {
      white-space: nowrap;
    }
  }

  .observer-list {
    .observer-list-item:not(:last-child) {
      border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }
  }
}

.custom-border {
  border-bottom: 0.125rem solid $color-milk;
}

@include media-breakpoint-up(lg) {
  .sbb-container:has(.sidebar.-open) .form-container {
    margin-right: calc(var(--sidebar-width-desktop) + 3rem); // 3rem => gray bar with icons
    transition: margin-right 0.35s ease;
  }
}

@include media-breakpoint-down(lg) {
  .sbb-container:has(.sidebar.-open) .up-button {
    height: rem(35);

    .up-button-content {
      height: rem(35);
      padding: 0 rem(3);

      span {
        display: none;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .sbb-container:has(.sidebar.-open) .up-button {
    --sbb-container-and-sidebar-width-desktop: calc(var(--sidebar-width-tablet) + #{rem(88)});
    margin-right: var(--sbb-container-and-sidebar-width-desktop);
    transition: margin-right 0.35s ease;
  }
}

@include media-breakpoint-up(lg) {
  .sbb-container:has(.sidebar.-open) .up-button {
    --sbb-container-and-sidebar-width-desktop: calc(var(--sidebar-width-desktop) + #{rem(16)} + 5.5%);
    margin-right: var(--sbb-container-and-sidebar-width-desktop);
    transition: margin-right 0.35s ease;
  }
}

@include media-breakpoint-up(xl) {
  .sbb-container:has(.sidebar.-open) .up-button {
    --sbb-container-and-sidebar-width-desktop: calc(var(--sidebar-width-desktop) + 8.5% + #{rem(16)});
    margin-right: var(--sbb-container-and-sidebar-width-desktop);
    transition: margin-right 0.35s ease;
  }
}

@include media-breakpoint-up(xxl) {
  .sbb-container:has(.sidebar.-open) .up-button {
    --sbb-container-and-sidebar-width-desktop: calc(var(--sidebar-width-desktop) + ((100vw - #{rem(1580)}) / 2));
    margin-right: var(--sbb-container-and-sidebar-width-desktop);
    transition: margin-right 0.35s ease;
  }
}
