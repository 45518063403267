@use '../functions' as *;

header,
.header {
  background-color: $color-white;
  border-bottom: 1px solid $color-silver;
  height: var(--header-height);
  position: sticky;
  top: 0;
  z-index: z-index(page-layout, header);

  &__wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
  }

  &__logo {
    height: rem(24);
  }
  
  a.help-link {
    color: $color-metal;

    svg {
      fill: $color-metal;
    }

    &:hover {
      color: $color-red125;
    }

    &:hover svg {
      fill: $color-red125;
    }
  }
}
