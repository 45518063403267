@use '../../functions' as *;

.workflow-overview {
  .all-done-container {
    background-color: $color-milk;
  }
}

.workflow-overview {
  .all-done-container {
    background-color: $color-milk;
  }
}

.text-tab-switcher {
  .btn-group {
    .btn {
      background-color: #f6f6f6;
      border: 0.0625rem solid #767676;
      color: #767676;

      &.active {
        background-color: #fff;
        border: 0.125rem solid #000;
        border-radius: 0.125rem;
        color: #000;
      }
    }
  }
}
