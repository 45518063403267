@use '../functions' as *;

:root {
  --header-height: #{rem(64)};
  --footer-height: #{rem(96)};

  --divider-big: #{rem(48)};
  --divider-medium: #{rem(48)};
  --divider-small: #{rem(24)};
  --divider-thin: #{rem(8)};

  @include media-breakpoint-up(md) {
    --divider-big: #{rem(72)};
  }

  @include media-breakpoint-up(lg) {
    --divider-big: #{rem(96)};
  }
}
