@use '../../functions' as *;

.progress-bar {
  $color-progress: $color-red;
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;

  &.-gray {
    $color-progress: $color-granite;
  }

  &__step {
    background-color: $color-storm;
    border-radius: 50%;
    z-index: 1;

    &.-highlighted {
      background-color: $color-progress;

      +.line {
        background-color: $color-progress;

      }
    }

    +.line {
      background-color: $color-storm;
      content: '';
      position: absolute;
    }
  }
}
