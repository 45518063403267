@use '../functions' as *;

h1,
.display-1 {
  font-size: rem(28);
  font-weight: 100;
  line-height: 1.2;
  margin-bottom: var(--divider-medium);

  @include media-breakpoint-up(md) {
    font-size: rem(30);
  }
  @include media-breakpoint-up(lg) {
    font-size: rem(40);
  }
}

h2,
.display-2 {
  font-size: rem(24);
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: var(--divider-medium);
  margin-top: var(--divider-medium);

  @include media-breakpoint-up(lg) {
    font-size: rem(32);
  }
}

h3,
.display-3 {
  font-size: rem(20);
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: var(--divider-small);

  @include media-breakpoint-up(lg) {
    font-size: rem(21);
  }
}

h4,
.display-4 {
  font-size: rem(15);
  font-weight: bold;
  line-height: 1.5;
}

.lead {
  font-size: rem(20);
  font-weight: 300;
  line-height: 1.3;

  @include media-breakpoint-up(md) {
    font-size: rem(21);
  }

  @include media-breakpoint-up(lg) {
    font-size: rem(21);
    line-height: rem(32);
  }
}
