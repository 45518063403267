/*stylelint-disable selector-class-pattern*/
@use '../../functions' as *;

$color-input-disabled: #efefef4d;

.tomselected + * {
  @extend .sbb-input;
  padding: rem(7.2) rem(8);

  &.ts-wrapper:not(.form-control):not(.form-select).single .ts-control {
    background-image: none;
    background-position: right 0 center;
    background-size: rem(18);
    padding: rem(4) rem(16);

    &::after {
      content: url('/public/icons/arrow-down-black.svg');
      height: rem(20);
      margin-right: rem(16);
      position: absolute;
      right: 0;
      transform: rotate(0deg) scale(0.7);
      transition: 0.2s ease-in-out;
    }
  }

  &.ts-wrapper:not(.form-control):not(.form-select) {
    @extend .sbb-input;
    padding: 0;

    &.disabled .ts-control {
      background-color: $color-input-disabled;
      color: $color-granite;
      opacity: unset;
    }

    &.focus,
    &.input-active {
      border: 1px solid $color-granite;
      border-radius: rem(2) rem(2) 0 0;

      &.dropdown-active {
        border-bottom-color: $color-graphite;

        .ts-control::after {
          transform: rotate(-180deg) scale(0.7);
          transition: 0.2s ease-in-out;
        }
      }
    }
  }

  .ts-dropdown .active {
    background-color: transparent;
  }

  &.ts-wrapper.multi.has-items {
    .item {
      background-color: $color-milk;
      font-size: rem(14);

      &.active {
        background-color: $color-red125;
      }
    }
  }

  &.ts-wrapper.filter.multi {
    cursor: pointer;

    input,
    .ts-control {
      cursor: pointer;
    }

    .ts-control {
      margin-right: rem(48);
      padding-left: rem(8);
    }

    &::after {
      content: url('/public/icons/arrow-down-black.svg');
      height: rem(20);
      margin-right: rem(16);
      position: absolute;
      right: rem(-6);
      top: rem(6);
      transform: rotate(0deg) scale(0.7);
      transition: 0.2s ease-in-out;
      z-index: 1;
    }

    &.dropdown-active::after {
      transform: rotate(180deg) scale(0.7);
    }
  }

  .ts-control {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  }

  .ts-dropdown {
    border: 1px solid $color-granite;
    border-radius: 0 0 rem(2) rem(2);
    border-top: 0;
    box-shadow: 0 rem(4) 0 rgba($color-black, 0.15);
    left: rem(-1);
    margin: 0;
    top: calc(100% + 1px);
    width: calc(100% + #{rem(2)});

    .ts-dropdown-content {
      max-height: unset;
      overflow: hidden;
    }
  }

  .option {
    padding: rem(4) rem(14);

    &.active {
      background-color: unset;
      color: $color-red125;
    }

    &.no-more-results {
      padding-bottom: 0;
    }
  }
}

.tomselected + *.ts-wrapper.multi.has-items.plugin-remove_button .item {
  background-color: $color-cloud;
  border-radius: rem(50);
  padding: rem(3) rem(15);

  a {
    border-left: 0;
    font-size: 0;
    height: rem(24);
    line-height: normal;
    padding-right: rem(10);

    &::after {
      content: url('/public/icons/cross-white.svg');
      display: block;
      scale: 0.8;
    }
  }
}

.tomselected + *.ts-wrapper:not(.form-control):not(.form-select).red {
  border: 1px solid $color-red;
  height: rem(48);
  padding: 0;

  &:hover:not(.disabled),
  &.dropdown-active:not(.disabled) {
    border-color: $color-red125;

    .ts-control {
      background-color: $color-red125;
    }
  }

  &.disabled {
    opacity: 0.4;
  }

  &.dropdown-active .ts-control::after {
    transform: rotate(-180deg);
    transition: 0.3s ease-in-out;
  }

  .ts-control {
    background-color: $color-red;
    background-image: none;
    border-color: $color-red;
    border-radius: 0;
    border-width: 1px;
    color: $color-white;
    padding: rem(10) rem(14);

    &::after {
      content: url('/public/icons/arrow-down-white.svg');
      display: block;
      height: rem(20);
      position: absolute;
      right: rem(14);
      transform: rotate(0deg);
      transition: 0.3s ease-in-out;
    }
  }
}

.tomselected + *.ts-wrapper {
  .option {
    border-left: 1px solid transparent;

    &:hover {
      background-color: $color-milk;
      border-left: 1px solid $color-red125;;
    }

    &[aria-disabled='true'] {
      background-color: $color-cloud;
      border-left: 1px solid $color-black;
      color: $color-black;

      &[id*=workflowGhosts][id*=type] {
        display: none;
      }
    }
  }
}

.tomselected[name*=workflowGhosts][name*=type] +* .option[aria-disabled='true'] {
  display: none;
}

.tomselected + *.ts-wrapper.filter {
  .ts-control {
    flex-wrap: nowrap;
    max-height: rem(34);

    >input {
      min-width: 0;
    }

    .item {
      background-color: transparent;
      margin-right: rem(5);
      padding: 0;
      pointer-events: none;
      position: relative;

      &:not(:last-of-type)::after {
        content: ',';
        display: block;
        position: absolute;
        right: rem(-3);
      }
    }
  }


  .optgroup>div:first-child {
    border-left: 1px solid transparent;
    padding: rem(4) rem(8);

    &:hover {
      background-color: $color-milk;
      border-left: 1px solid $color-red125;;
    }

    &[aria-disabled='true'] {
      background-color: $color-cloud;
      border-left: 1px solid $color-black;
      color: $color-black;
    }
  }

  .optgroup::before {
    display: none;
  }

  .optgroup-header {
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }
  }

  &.plugin-sbb-multiselect .option {
    display: flex;
    padding-left: rem(32);
  }

  .closed {
    .option {
      display: none;
    }

    .dropdown-icon {
      transform: rotate(180deg);
      transition: 0.3s ease-in-out;
    }
  }

  .dropdown-icon {
    margin-right: rem(8);
    position: absolute;
    right: 0;
    scale: 0.8;
    transition: 0.3s ease-in-out;
    z-index: 0;

    &:hover {
      cursor: pointer;
    }
  }

  input[type='checkbox'] {
    height: rem(20);
    opacity: 0;
    position: absolute;
    width: rem(20);
    z-index: z-index(page-layout, checkbox-input);

    &:checked + .sbb-checkbox-label {
      color: $color-iron;

      /* stylelint-disable-next-line selector-max-compound-selectors */
      .icon.tick {
        visibility: visible;
      }
    }

    &:indeterminate + .sbb-checkbox-label {
      color: $color-iron;

      /* stylelint-disable-next-line selector-max-compound-selectors */
      .icon.indeterminate {
        left: rem(-2);
        scale: 0.8;
        visibility: visible;
      }
    }

    &:disabled + .sbb-checkbox-label {
      color: $color-storm;

      &::before {
        background-color: $color-milk;
        border-color: $color-aluminium;
      }
    }
  }

  .sbb-checkbox-label {
    align-items: center;
    color: $color-granite;
    display: flex;
    margin: 0;
    padding: 0;
    position: relative;

    &::before {
      border: 1px solid $color-graphite;
      border-radius: rem(2);
      content: '';
      display: inline-block;
      height: rem(20);
      margin-right: rem(4);
      min-width: rem(20);
      position: relative;
      width: rem(20);
      z-index: z-index(page-layout, checkbox-box);
    }

    .icon {
      left: rem(-2);
      position: absolute;
      visibility: hidden;
      z-index: z-index(page-layout, checkbox-tick);
    }
  }
}

.select-word-break {
  .ts-control .item {
    word-wrap: anywhere;
  }
}

