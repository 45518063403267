@use '../../functions' as *;

.copy-field {
  cursor: pointer;
}

.production-table {
  table {
    border-collapse: collapse;
    table-layout: fixed; /* Ensures uniform column width */
  }

  th,
  td {
    padding: rem(4);
  }

  tbody {
    tr:hover {
      background-color: $color-milk;
      border-bottom: rem(1) solid $color-red;
    }
  }


  thead {
    tr {
      border-bottom: rem(1) solid $color-graphite;
    }

    tr > *:not(:first-child) {
      border-left: rem(1) solid $color-graphite;
      text-align: center;
    }
  }

  .language-version {
    padding-left: rem(16);
    text-align: left !important;
    word-break: break-word;
  }

  tr {
    border-bottom: rem(1) solid $color-cloud;
  }

  th {
    color: $color-granite;
  }


  tr > *:not(:first-child) {
    border-left: rem(1) solid $color-cloud;
    text-align: center;
  }

  tr > :first-child {
    padding-left: rem(16);
  }

  td {
    height: rem(32); /* Assuming a fixed height for cells */
  }

}


.upload-spinner-wrapper {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  display: none;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
}

.upload-spinner {
  animation: spin 2s linear infinite;
  border: rem(4) solid $color-silver;
  border-radius: 50%;
  border-top: rem(4) solid $color-red;
  display: block;
  height: 2.5rem;
  width: 2.5rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
