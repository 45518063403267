@use '../../functions' as *;

.workflow-element {
  border: 1px solid $color-graphite;
  list-style: none;
  margin-bottom: var(--divider-small);
  padding: var(--divider-small);
}

.we-header {
  align-items: center;
  border-bottom: 1px solid $color-graphite;
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--divider-small);
}

.we-functions-wrap {
  display: flex;
  gap: var(--divider-small);
}

.we-content {
  padding-top: var(--divider-small);
}

.background-silver {
  background-color: #f6f6f6;
}

.tab-content {
  background-color: #fff;
}

.clipboard-button {
  background-color: #fff;
}

.frontify-button {
  background-color: #dcdcdc;
}

.has-error {
  border: 1px solid $color-red !important;
}
