.w-fit {
  width: fit-content;
}

.h-fit {
  height: fit-content;
}

.cut-text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.remove-label-spacing .sbb-label {
  padding: 0;
}

