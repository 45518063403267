@use '../functions' as *;

.froala-redasys-wrapper {
  .fr-class-red-highlighted {
    color: $color-red125;
  }

  .fr-class-inline {
    color: $color-black;
  }

  .fr-second-toolbar {
    border-color: $color-graphite;
    border-radius: 0 0 rem(2) rem(2);
  }

  .fr-toolbar.fr-top {
    border-color: $color-graphite;
    border-radius: rem(2) rem(2) 0 0;
    top: 0 !important;
  }

  // dashes in leapt_froala_editor.yaml are automatically converted to underscores when the froala element is generated from the backend
  // thus, the appropriate styles are added below
  /* stylelint-disable */
  .fr_class_red_highlighted {
    color: $color-red125;
  }

  .fr_class_inline {
    color: $color-black;
  }

  .fr_second_toolbar {
    border-color: $color-graphite;
    border-radius: 0 0 rem(2) rem(2);
  }

  .fr_toolbar.fr_top {
    border-color: $color-graphite;
    border-radius: rem(2) rem(2) 0 0;
    top: 0 !important;
  }

  .fr-view {
    .fr-highlight-change,
    .fr-tracking-deleted {
      background: none;
      color: $color-red125;
      opacity: 1;
    }

    .last-track-change {
      background-color: $color-graphite;
      background-color: rgba($color-graphite, 0.4);
    }

    .fr-highlight-change {
      text-decoration: underline;
    }

    .fr-tracking-deleted {
      text-decoration: line-through;
    }
  }

  a {
    text-decoration: underline;
  }

  .fr-toolbar .fr-command.fr-btn img {
    width: rem(24);
    height: rem(24);
  }
}
