@mixin font-fix-ctrl-a-delete {
  // Fix for contenteditable ctrl+A delete
  font {
    color: $color-black;

    u {
      text-decoration: none;
    }
  }
}
