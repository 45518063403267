@use '../../functions' as *;
@import '../../mixins/font-fixes';

.mention {
  &-field {
    @extend .sbb-input;
    @include font-fix-ctrl-a-delete;

    background-color: $color-white;
    height: rem(100);
    margin-bottom: 0;
    overflow: auto;

    &:empty::before {
      color: $color-storm;
      content: attr(data-placeholder);
    }

    &:empty:focus::before {
      content: '';
    }

    .mention-tag {
      color: $color-blue-sky;
      text-decoration: underline;
    }
  }

  &-suggestion-list {
    box-shadow: 0 rem(4) 0 rgba($color-black, 0.15);
    position: absolute;
    width: 100%;
    z-index: z-index(sidebar, suggestionList);

    &-item {
      &.-active,
      &:focus,
      &:hover {
        background-color: $color-cloud;
      }

      .mention-tag {
        pointer-events: none;
      }
    }
  }
}
