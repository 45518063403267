@use '../../functions' as *;

.video-format-card {
  background-color: $color-milk;
  margin: rem(2);
  padding: 1rem;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: rem(366);
  }

  &-adjustable {
    @extend .video-format-card;
    height: fit-content;
  }

  &-editable {
    @extend .video-format-card;
    background-color: $color-cloud;
  }

  &-upper-section {
    padding-bottom: 1rem;
    padding-right: rem(12);
    width: 100%;
  }

  &-lower-section {
    border: 0;
    width: 100%;

    @include media-breakpoint-down(md) {
      height: auto;
    }

    &-inner-box {
      border-top: 1px solid $color-graphite;
      padding-top: 1rem;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        height: auto;
      }
    }

    &-inner-disabled-box {
      border-top: 1px solid $color-silver;
      padding-top: 1rem;

      @include media-breakpoint-down(md) {
        height: auto;
      }
    }
  }

}

.video-format-screen-preview-box {
  height: rem(64);
  width: rem(64);

  &-figure {

    background-color: $color-milk;
    border: 1px solid $color-metal;
    border-color: 1px solid $color-metal;
    border-radius: rem(6);

    &.-checked {
      background-color: $color-aluminium;
      border-color: 1px solid $color-iron;
    }

  }
}

.video-format-aspect-ratio-box {
  height: 100%;
  width: rem(180);

  &-divider {
    margin-left: rem(17);
    margin-right: rem(17);
  }
}

.video-format-toolbar-button {
  height: rem(40);
  margin-bottom: 0;
  margin-left: rem(8);
  width: rem(40);
}

.video-format-input {
  width: rem(72);
}
