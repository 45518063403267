@use '../functions' as *;

@mixin make-container($margin-x, $max-width: 0) {
  @if $max-width == 0 {
    margin-left: $margin-x;
    margin-right: $margin-x;
  } @else {
    margin-left: auto;
    margin-right: auto;
    max-width: $max-width;
  }
}

.sbb-container {
  @include make-container(1.6rem);
  z-index: z-index(page-layout, header);

  @include media-breakpoint-up(md) {
    @include make-container(3.2rem);
  }

  @include media-breakpoint-up(lg) {
    @include make-container(5.5%);
  }

  @include media-breakpoint-up(xl) {
    @include make-container(2%);
  }

  @include media-breakpoint-up(xxl) {
    @include make-container(0, rem(1594));
  }
}

main {
  min-height: calc(100vh - #{var(--header-height)} - #{var(--footer-height)});
  padding: var(--divider-big) 0;
}

.layout-columns {
  margin-left: 0;
  width: 100%;

  @include media-breakpoint-up(md) {
    margin-left: 16.66667%;
    width: 66.66667%;
  }
}

.layout-centered-content {
  max-width: 100%;

  @include media-breakpoint-up(md) {
    margin-left: auto;
    margin-right: auto;
    max-width: 66.13333em;
  }
}

.sbb-overlay-blocker {
  background-color: $color-white;
  height: 100%;
  opacity: 40%;
  pointer-events: auto;
  position: absolute;
  width: 100%;
  z-index: z-index(page-layout, overlayBlocker);
}
