@use '../functions' as *;

.dashboard {
  .nav-tabs {
    flex-wrap: nowrap;
    min-width: fit-content;
  }

  .tab-scroll {
    overflow-y: hidden;

    ul {
      padding: 0 rem(52);
    }

    .scroll-indicator {
      top: 0;
      z-index: 5;

      &:last-child {
        right: rem(16);
      }

      .gradient {
        background: linear-gradient(to right, $color-white, transparent);
        margin-bottom: 1px;
        width: rem(16);

        &.right {
          background: linear-gradient(to left, $color-white, transparent);
        }
      }

      [data-action*='scroll'] {
        background-color: $color-white;
        border-bottom: 1px solid var(--bs-gray);
        height: rem(36);
        width: rem(36);
      }

      svg {
        height: rem(20);
        width: rem(20);
      }
    }
  }

  .nav-link {
    white-space: nowrap;
  }
}
