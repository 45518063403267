@use '../functions' as *;

footer,
.footer {
  border-top: 1px solid $color-silver;
  font-size: rem(12);
  height: var(--footer-height);

  &__link {
    color: $color-metal;
  }

  &.-sidebar-open {
    margin-right: 0;

    @include media-breakpoint-up(lg) {
      margin-right: calc(var(--sidebar-width-desktop) + 3rem);
    }
  }
}
