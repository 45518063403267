@use '../functions' as *;
@include action-button;

.login-page .nav-tabs {
  --bs-nav-tabs-border-width: 0;
  --bs-nav-tabs-link-active-border-color: $color-black;
  --bs-nav-link-font-size: x-large;
  --bs-nav-link-font-weight: 200;
  --bs-nav-link-padding-y: 0;
  --bs-nav-link-padding-x: 0;
}

.login-page {
  .nav-link.active {
    border-bottom: rem(1) solid;
  }

  .nav-link:not(.active):hover {
    border-bottom: rem(1) solid $color-red;
  }

  .sbb-form-field {
    min-width: rem(240);
  }

  .internal-login-button {
    padding: 0 rem(16);
  }
}
