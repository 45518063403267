@use '../../functions' as *;

.clear-filter-button {
  height: rem(37);

  > svg.icon-cross {
    margin-top: rem(-2);
  }
}

.clear-all-filters-button:hover {
  cursor: pointer;
}

.multiselect-wrapper>div {
  max-width: calc(100% - rem(72));
}

.arrow-icon {
  max-height: rem(20);

  &.rotate {
    transform: rotate(-180deg);
  }
}

.no-results-container {
  background-color: $color-milk;

  svg {
    min-width: rem(24);
  }
}

[data-controller='filter']>div {
  max-width: calc(100% - rem(48));
}
