@use '../../functions' as *;

.dropdown.task-menu {
  &:has([data-popper-placement*='bottom']) {
    .dropdown-toggle.show {
      border-bottom: 1px solid transparent;
    }
  }

  &:has([data-popper-placement*='top']) {
    .dropdown-toggle.show {
      border-top: 1px solid transparent;
    }
  }

  .dropdown-toggle {
    background-color: transparent;
    border: 1px solid transparent;
    height: rem(25);

    &.show {
      background-color: $color-white;
      border: 1px solid $color-black;
      z-index: z-index(page-layout, task-menu-toggle);
    }

    &::after {
      content: none;
    }
  }

  .dropdown-menu {
    z-index: z-index(page-layout, task-menu-dropdown);
  }
}
