/*stylelint-disable selector-class-pattern*/
@use '../../functions' as *;

.sbb-form-datepicker-field {
  position: relative;

  .icon {
    left: rem(4);
    position: absolute;
    stroke: $color-granite;
    top: rem(6);
  }

  .sbb-datepicker {
    @extend .sbb-input;

    &.input {
      appearance: none;
      padding-bottom: rem(5);
      padding-left: rem(32);
      width: fit-content;
    }

    &__icon-wrapper {
      cursor: pointer;

      .icon {
        z-index: z-index(sbb-form-field, icon);
      }
    }
  }
}

.flatpickr-wrapper {
  width: 100%;
}

.flatpickr-calendar {
  border: 1px solid $color-granite;
  border-radius: rem(2);
  box-shadow: 0 rem(4) 0 rgba($color-black, 0.15);
  color: $color-silver;
  width: auto;


  &.arrowBottom {
    margin-top: rem(-14);
  }

  &.arrowTop {
    margin-top: rem(9);
  }

  .flatpickr-days {
    margin: var(--divider-small);

    .flatpickr-day {
      border-width: rem(2);
      color: $color-granite;

      &.today {
        border-color: transparent;
        font-weight: bold;
      }

      &.selected {
        background: $color-white;
        border: rem(2) solid $color-granite;
      }

      &:not(.flatpickr-disabled) {
        &:focus,
        &:hover {
          background: $color-white;
          border: rem(2) solid $color-red125;
          color: $color-red125;
        }}

      &.prevMonthDay,
      &.nextMonthDay {
        visibility: hidden;
      }
    }

    .flatpickr-disabled {
      color: $color-graphite;

      &::after {
        background-color: $color-graphite;
        content: '';
        font-weight: bold;
        height: 1px;
        left: rem(7);
        position: absolute;
        top: rem(19);
        transform: rotate(135deg);
        width: rem(24);
      }
    }
  }


  .flatpickr-months {
    border-right: 1px solid;
    max-width: 60%;
    padding: var(--divider-thin);
  }

  .flatpickr-months .flatpickr-next-month {
    right: 40%;
  }

  .flatpickr-current-month {
    padding-top: rem(6);

    &:hover:not(.disabled)::after {
      content: url('/public/icons/arrow-down-black.svg');
      position: absolute;
      scale: 0.75;
      top: rem(4);
    }
  }

  .flatpickr-monthDropdown-months {
    appearance: none;
    text-align-last: center;

    &:disabled {
      cursor: initial;
    }

    &:hover {
      background-color: $color-white;
    }
  }

  .flatpickr-custom-years {
    width: 40%;
  }

  .numInputWrapper {
    width: fit-content;
  }

  input.numInput.cur-year {
    border: 1px solid transparent;
    border-radius: rem(2);
    font-size: 135%;
    font-weight: 300;
    outline: none;
    padding-top: rem(3);
    width: rem(68);

    &:not(disabled) {
      &:hover,
      &:focus,
      &:active {
        border-color: $color-iron;
      }
    }

    &:disabled {
      background-color: $color-white;

      &:hover {
        border-color: transparent;
      }
    }
  }

  button.end-0,
  button.start-0 {
    top: rem(1);
  }

  svg {
    height: rem(14);
    width: rem(14);
  }

  .flatpickr-weekdays {
    border-bottom: 1px solid $color-silver;
    border-top: 1px solid $color-silver;
    padding: 0 var(--divider-small);
  }

  &.arrowTop::before {
    border-bottom-color: $color-granite;
    border-width: rem(10);
    margin-bottom: rem(2);
    top: rem(-20);
  }

  &.arrowBottom::before {
    border-top-color: $color-granite;
    border-width: rem(10);
    margin-top: rem(2);
  }

  &.arrowBottom::after,
  &.arrowTop::after {
    border-width: rem(9);
  }
}
