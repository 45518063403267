@use '../../functions' as *;

$tippy-array-width: rem(8);

.tippy-box[data-theme~='sbb'] {
  background: $color-white;
  border: 1px solid $color-granite;
  box-shadow: 0 0.23333em 0 0 rgba(102, 102, 102, 0.15);
  color: $color-black;
  font-size: 1em;
  position: relative;
}

.tippy-arrow {
  color: $color-white;

  &::before,
  &::after {
    border-color: transparent;
    border-style: solid;
    content: '';
    position: absolute;
  }
}

.tippy-box[data-placement^='top'] {
  > .tippy-arrow {
    bottom: 0;

    &::before {
      border-bottom-width: 0;
      border-left-width: $tippy-array-width;
      border-right-width: $tippy-array-width;
      border-top-color: $color-granite;
      border-top-width: $tippy-array-width;
      bottom: $tippy-array-width * (-1) - rem(1);
    }

    &::after {
      border-top-color: $color-white;
      border-width: $tippy-array-width $tippy-array-width 0;
      bottom: $tippy-array-width * -1;
    }
  }
}
