@import '~bootstrap/scss/functions';
@import 'settings/custom-variables';

@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';

@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/bootstrap-utilities';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/accordion';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/carousel';

@import 'tippy.js/dist/tippy.css';
@import 'tom-select/dist/css/tom-select.bootstrap5.css';
@import '~flatpickr';

@import 'fonts/sbb-fonts';

@import 'settings/typo';
@import 'settings/sizes';

@import 'mixins/action-arrows';
@import 'mixins/action-button';

@import 'inc/layout';
@import 'inc/header';
@import 'inc/footer';

@import 'inc/login';
@import 'inc/dashboard';
@import 'inc/imprint';
@import 'inc/help';

@import 'inc/components/accordion';
@import 'inc/components/button';
@import 'inc/components/dashboard-table';
@import 'inc/components/datepicker';
@import 'inc/components/desknet-overview';
@import 'inc/components/dropdown';
@import 'inc/components/filter';
@import 'inc/components/form-fields';
@import 'inc/components/frontify-finder';
@import 'inc/components/link';
@import 'inc/components/linkbox';
@import 'inc/components/modal';
@import 'inc/components/notification';
@import 'inc/components/notification-lyne';
@import 'inc/components/progress-bar';
@import 'inc/components/select';
@import 'inc/components/sidebar';
@import 'inc/components/stepbadge';
@import 'inc/components/tooltip';
@import 'inc/components/usermenu';
@import 'inc/components/workflow-overview';
@import 'inc/components/video-format';
@import 'inc/components/target-platform';
@import 'inc/components/mention';
@import 'inc/components/video-workflow';
@import 'inc/components/summary';
@import 'inc/components/task-menu';
@import 'inc/components/toggle';
@import 'inc/components/clipboard';
@import 'inc/components/storyboard';
@import 'inc/components/video-production';
@import 'inc/components/video-release';
@import 'inc/components/workflow-element';
@import 'inc/components/header-image';
@import 'inc/components/newsarticle-image';
@import 'inc/components/newsarticle-gallery';

@import 'utility-classes';

@import '~froala-editor/css/froala_editor.pkgd.min.css';

@import 'inc/froala-editor';
