/*stylelint-disable meowtec/no-px*/
$color-black: #000;
$color-white: #fff;

$color-red: #eb0000;
$color-red125: #c60018;
$color-red150: #a20013;

$color-green: #00973b;
$color-peach: #fcbb00;
$color-blue-sky: #0079c7;

$color-milk: #f6f6f6;
$color-cloud: #e5e5e5;
$color-silver: #dcdcdc;
$color-aluminium: #d2d2d2;
$color-platinum: #cdcdcd;
$color-cement: #bdbdbd;
$color-graphite: #b7b7b7;
$color-storm: #a8a8a8;
$color-smoke: #8d8d8d;
$color-metal: #767676;
$color-granite: #686868;
$color-anthracite: #5a5a5a;
$color-iron: #444;

$color-primary: $color-red;
$color-secondary: $color-red125;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 643px,
  lg: 1025px,
  xl: 1281px,
  xxl: 1920px
);

$theme-colors: (
  'primary':    $color-primary,
  'secondary':  $color-secondary,
  'light':      $color-metal,
  'gray':       $color-graphite,
  'dark':       $color-granite,
  'milk':       $color-milk,
  'silver':     $color-silver,
  'black':      $color-black,
  'white':      $color-white,
);

$font-family-base: 'SBBWeb', sans-serif;
$font-size-base: 0.9375rem;
$font-size-root: 16px;
$line-height-base: 1.7;

$body-color: $color-black;
$border-radius: 3px;
$grid-gutter-width: 2rem;

$link-color: $color-black;
$link-decoration: none;
$link-hover-color: $color-red125;
$link-hover-decoration: none;

$nav-link-padding-y: 0.25rem;
$nav-link-padding-x: 1rem;
$nav-link-color: $color-granite;
$nav-link-hover-color: $color-red125;
$nav-tabs-link-active-color: $color-black;
$nav-tabs-link-hover-border-color: transparent transparent $color-red125 transparent;
$nav-tabs-border-color: $color-graphite;
$nav-tabs-link-active-border-color: $color-graphite $color-graphite $color-white;
$nav-tabs-border-radius: 3px;

$badge-border-radius: 2px;
$badge-font-size: 1em;
$badge-font-weight: normal;

$tooltip-font-size: 1em;
$tooltip-color: $color-black;
$tooltip-bg: $color-white;
$tooltip-margin: 0;
$tooltip-arrow-width: 0.8rem;
$tooltip-arrow-height: 0.4rem;

$dropdown-link-hover-bg: $color-milk;
$dropdown-link-color: $color-iron;
$dropdown-link-hover-color: $color-red125;
$dropdown-link-active-color: $color-black;
$dropdown-link-active-bg: $color-cloud;
$dropdown-border-color: $color-black;
$dropdown-border-radius: 0;

$accordion-button-bg: $color-milk;
$accordion-button-active-bg: $color-white;
$accordion-border-color: $color-cloud;
$accordion-button-active-color: $color-black;
$accordion-button-focus-border-color: $color-milk;
$accordion-button-focus-box-shadow: none;
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36'><path fill='none' stroke='#000' d='M18.75,5.2497 C25.377,5.2497 30.75,10.6227 30.75,17.2497 C30.75,23.8782 25.377,29.2497 18.75,29.2497 C12.123,29.2497 6.75,23.8782 6.75,17.2497 C6.75,10.6227 12.123,5.2497 18.75,5.2497 Z M12,17.25045 L25.5,17.25045 M18.75,10.50045 L18.75,24.00045'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36'><path fill='none' stroke='#000' d='M18.75,5.2497 C25.377,5.2497 30.75,10.6227 30.75,17.2497 C30.75,23.8782 25.377,29.2497 18.75,29.2497 C12.123,29.2497 6.75,23.8782 6.75,17.2497 C6.75,10.6227 12.123,5.2497 18.75,5.2497 Z M12,17.25045 L25.5,17.25045'/></svg>");
$accordion-icon-transform: none;
$accordion-padding-y: var(--divider-small);
$accordion-padding-x: var(--divider-small);
$accordion-icon-width: 36px;

$modal-backdrop-bg: $color-white;
$modal-backdrop-opacity: 0.7;
$modal-content-border-radius: 0;
$modal-content-border-color: $color-granite;

$list-group-item-padding-y: 0.25rem;
$list-group-item-padding-x: 0.5rem;
$list-group-border-color: $color-graphite;
$list-group-border-radius: 0;

$form-check-input-bg: $color-smoke;
$form-check-input-checked-bg-color: $color-red;
$form-check-input-focus-border: 0;
$form-check-input-focus-box-shadow: 0;
$form-check-input-border: 0;
$form-check-input-width: 2em;

$form-switch-width: 3em;
$form-switch-bg-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
$form-switch-focus-bg-image: $form-switch-bg-image;

$breadcrumb-divider: quote('>');
$breadcrumb-active-color: $color-red;

$enable-negative-margins: true;
