@use '../../functions' as *;
// Disabled until lyne gets integrated properly
/* stylelint-disable */
.sbb-notification-lyne {
  .sbb-notification__wrapper {
    position: relative;
    inset-inline-start: rem(7);
    margin-right: rem(7);
    border-top-left-radius: rem(4);
    border-top-right-radius: rem(8);
    border-bottom-left-radius: rem(4);
    border-bottom-right-radius: rem(8);
    border: 1px solid;

    &::before {
      content: '';
      position: absolute;
      border: 1px solid;
      border-radius: rem(8);
      inset: -1px 8px -1px -8px;
    }
  }

  .sbb-notification {
    position: relative;
    padding: rem(24);
    border-top-left-radius: rem(4);
    border-top-right-radius: rem(8);
    border-bottom-left-radius: rem(4);
    border-bottom-right-radius: rem(8);
  }

  &.warning {
    .sbb-notification__wrapper {
      border-color: $color-peach;

      &::before {
        background-color: $color-peach;
        border-color: $color-peach;
      }
    }

    .sbb-notification {
      background-color: #fffbf2;

      &.-warning {
        background-color: $color-peach;
        color: $color-black;

        .icon path {
          stroke: $color-black;
        }
      }
    }

    .sbb-checkbox-label:before {
      background-color: white;
    }
  }
}
/* stylelint-enable */
