@use '../../functions' as *;

.dashboard-table {
  &__head {
    font-weight: bold;

    [data-sorting] svg {
      display: none;

      &.active {
        display: block;
      }
    }


    &:has([data-sorting]:hover) svg {
      display: none;
    }

    [data-sorting]:hover {
      color: $color-red125;
      cursor: pointer;

      svg {
        display: block;

        &.active {
          transform: rotate(-180deg);
        }

        &.rotate {
          transform: rotate(0deg);
        }

        &.active.rotate {
          display: none;
        }
      }

      path {
        fill: $color-red125;
      }
    }

    > div[class*='col-'] {
      border-right: 1px solid $color-milk;
      padding-bottom: rem(5);

      &:last-child {
        border-right: 0;
      }
    }
  }

  &__body {
    border-bottom: 1px solid $color-white;

    > div[class*='col-'] {
      background-color: $color-milk;
      border: 0;
      padding-bottom: rem(5);
      padding-top: rem(5);

      @include media-breakpoint-up(lg) {
        border-bottom: 1px solid $color-white;
        border-right: 1px solid $color-white;

        &:last-child {
          border-right: 0;
          padding-bottom: 0;
          padding-top: 0;
        }
      }

      &:last-child {
        padding-right: rem(24);
      }
    }
  }
}
