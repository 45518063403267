@use '../../functions' as *;

.asset-container {
  border: rem(1) solid $color-silver;
  border-radius: rem(4);
}

.asset-header-image {
  aspect-ratio: 10 / 3;
  object-fit: cover;
}

.asset-wrapper:hover + .asset-header-image-overlay-wrapper {
  opacity: 1;
}

.asset-header-image-overlay-wrapper:hover {
  opacity: 1;
}

.asset-header-image-overlay-wrapper {
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  opacity: 0; /* Initially transparent */
  position: absolute;
  top: 0;
  transition: opacity 0.3s ease-in-out; /* Transition opacity */
  width: 100%;
}

.asset-header-image-overlay {
  background: $color-white;
  padding: rem(5) rem(15);
  @include media-breakpoint-up(xxl) {
    padding: rem(25);
  }
}

.sub-information {
  color: $color-graphite;
  font-size: rem(13);
}
