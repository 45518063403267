@use '../../functions' as *;

.linkbox {
  border: 1px solid $color-graphite;
  list-style: none;
  margin-bottom: var(--divider-small);
  padding-bottom: var(--divider-small);
  padding-top: var(--divider-small);

  &__item {
    border-bottom: 1px solid $color-silver;

    &:first-child {
      border-top: 1px solid $color-silver;
    }
  }

  &__link {
    padding-bottom: var(--divider-thin);
    padding-top: var(--divider-thin);

    .icon {
      width: rem(24);
    }

    &:hover {
      .icon path {
        stroke: $color-red125;
      }
    }
  }

  &__item {
    list-style: none;
  }
}
