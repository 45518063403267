@use '../functions' as *;

.help {
  .tile {
    img {
      height: rem(160);
      object-fit: cover;
    }

    a {
      background-color: $color-milk;
    }
  }

  .index-link {
    padding-bottom: var(--divider-thin);
    padding-top: var(--divider-thin);

    .icon {
      width: rem(24);
    }

    &:hover {
      .icon path {
        fill: $color-red125;
      }
    }
  }
}
