@use '../../functions' as *;

.newsarticle-gallery {
  position: relative;

  .slide-next,
  .slide-prev {
    align-items: center;
    background-color: $color-metal;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 2.5rem;
    justify-content: center;
    line-height: 1;
    opacity: 0.7;
    padding: 0.625rem 0.3125rem;
    position: absolute;
    top: 50%;
    transform: translateY(50%);
    transition: padding 0.4s ease-out;
    width: 2.5rem;
    z-index: 700;

    &:hover {
      opacity: 1;
    }

    span {
      color: #fff;
      display: block;
      position: relative;
    }
  }

  .slide-prev {
    left: 1.5rem;
  }

  .slide-next {
    right: 1.5rem;
  }

  .thumbnails {
    display: flex;
    flex-wrap: wrap;
    gap: rem(10);

    img {
      border: rem(2) solid transparent;
      cursor: pointer;
      max-width: rem(150);
    }

    img.active {
      opacity: 0.6;
    }

    img.error {
      border: rem(2) solid $color-red;
    }
  }
}
