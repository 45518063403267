@use '../../functions' as *;

.kaltura-player {
  aspect-ratio: 16 / 9;
}

.video-release-table {
  table {
    border-collapse: collapse;
  }

  td {
    background-color: $color-milk;
    padding: rem(5);
  }

  thead {
    tr > *:not(:first-child) {
      border-left: rem(1) solid $color-cloud;
      padding-left: rem(6);
    }
  }

  tbody {
    tr > *:not(:first-child) {
      border-left: rem(1) solid $color-white;
      padding-left: rem(6);
    }

    tr {
      border-bottom: rem(1) solid $color-white;
    }
  }
}
