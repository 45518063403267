@use '../../functions' as *;

.word-break {
  p {
    word-break: break-word;
  }
}

.sbb-accordion {
  z-index: z-index(page-layout, accordion);

  &__item {
    margin-bottom: rem(2);

    &:not(:first-of-type) {
      border-top: $accordion-border-width solid $accordion-border-color;
    }

    &.collapsed {
      border-color: var(--bs-accordion-btn-bg);
    }
  }

  &__button {
    font-weight: 300;

    &:not(.collapsed) {
      box-shadow: none;
    }
  }

  &__divider {
    border-color: $color-cloud;
    margin: 0 rem(24);
    opacity: 1;
  }
}

.accordion.dropdown-style .accordion-button {
  display: flex;
  font-size: rem(16);
  justify-content: space-between;

  &::after {
    display: none;
  }

  svg {
    transform: rotate(180deg);
  }

  &.collapsed svg {
    transform: rotate(0deg);
  }
}

.accordion.readonly .accordion-button {
  opacity: 0.4;
}
