@use '../../functions' as *;

.frontify-finder-wrapper {
  height: 100%;
}

.image-wrapper {
  .asset {
    width: 100%;
  }
}

.frontify-link-element {
  display: flex;
  gap: rem(10);
}

.frontify-link-contentwrap,
.frontify-link-assetwrap {
  width: 100%;
}

.frontify-link-fields {
  button {
    margin: 0;
  }
}

.frontify-button {
  align-items: center;
  background-color: $color-milk;
  border: solid 0.1rem $color-silver;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: row;
  gap: rem(20);
  padding: rem(8) rem(16);

  &:hover {
    box-shadow: 0 0 rem(15) 0 rgba(0, 0, 0, 0.5);
  }

  .iconwrap svg {
    height: 2rem;
    width: 2rem;
  }

  .textwrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  span {
    color: $color-iron;
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1.5;
    text-align: left;
  }

  span.large {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
}
