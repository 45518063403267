@use '../../functions' as *;

.plain-button {
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  padding: 0;
}

.sbb-button-big-text:hover {
  box-shadow: 0 0 rem(15) 0 rgba(0, 0, 0, 0.5);
}

.sbb-button-big-text {
  align-items: center;
  background-color: transparent;
  border: solid 0.1rem $color-silver;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: row;

  height: 7.625rem;

  span {
    color: $color-iron;
    font-size: 0.9375rem;
    font-weight: bold;
    line-height: 1.25rem;
    text-align: left;
  }

  span.big-text {
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }


}

.sbb-button {
  border: 0;
  border-radius: rem(2);
  cursor: pointer;
  font-weight: normal;
  line-height: 1rem;
  margin-bottom: var(--divider-thin);
  margin-left: 0;
  padding: rem(16);
  text-align: center;
  width: 100%;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
    margin-left: var(--divider-thin);
    text-align: left;
    width: auto;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.disabled {
    cursor: default;
    opacity: 0.4;
  }

  &:disabled {
    cursor: default;
    opacity: 0.4;
  }

  &.primary {
    background-color: $color-red;
    color: $color-white;

    &:hover:not(:disabled) {
      background-color: $color-red125;
    }

    .icon path {
      stroke: $color-white;
    }
  }

  &.primary-alt {
    background-color: $color-granite;
    color: $color-white;

    &:hover:not(:disabled) {
      background-color: $color-iron;
    }

    &.selected {
      background-color: $color-iron;

      svg {
        height: rem(16);
        scale: 1.5;
        width: rem(16);
      }
    }
  }

  &.secondary {
    background-color: $color-silver;
    color: $color-black;

    &:hover:not(:disabled) {
      background-color: $color-graphite;
    }
  }

  &.slim {
    padding: rem(8);
  }

  &.medium {
    padding: rem(10) rem(16);
  }

  &.icon-button {
    align-items: center;
    background-color: $color-granite;
    color: $color-white;
    display: flex;
    justify-content: space-between;
    line-height: 1rem;
    margin: 0;
    padding: rem(6) rem(6) rem(6) rem(16);
    text-align: left;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: fit-content;
    }

    .icon {
      flex-shrink: 0;
      margin-left: var(--divider-small);

      path {
        stroke: $color-white;
      }
    }

    &:disabled .icon {
      pointer-events: none;
    }

    &:not(:disabled):hover {
      background-color: $color-iron;
    }

    &.-light {
      background-color: $color-silver;
      color: $color-black;

      .icon path {
        stroke: $color-black;
      }

      &:not(:disabled):hover {
        background-color: $color-graphite;
      }
    }
  }

  &.radio-button {
    align-items: center;
    background-color: $color-granite;
    color: $color-white;
    cursor: pointer;
    display: flex;
    line-height: 1.5rem;
    padding: rem(6) rem(16);
    width: 100%;

    @include media-breakpoint-up(md) {
      width: fit-content;
    }

    .sbb-radiobutton-label {
      height: rem(20);
      width: rem(20);

      &::before {
        background-color: $color-white;
      }
    }

    &:hover {
      background-color: $color-iron;
    }
  }

  &.delete-button {
    padding: rem(12) rem(16);

    .trash-icon {
      margin-left: rem(16);
    }

    &.primary .trash-icon path {
      stroke: $color-white;
    }
  }

  &.enabled-click {
    z-index: z-index(page-layout, cancel-button);
  }
}

.up-button {
  background-color: rgba($color-white, 0.8);
  border: rem(2) solid $color-cloud;
  border-radius: rem(3);
  bottom: rem(40);
  font-weight: 300;
  margin-right: rem(88);
  padding: 0;
  position: fixed;
  right: 0;
  transition: bottom 0.3s ease;
  z-index: z-index(page-layout, upButton);

  &:hover,
  &:focus {
    color: $color-red125;

    .up-button-content svg path {
      fill: $color-red125;
    }
  }

  .up-button-content {
    align-items: center;
    color: currentColor;
    display: flex;
    flex-direction: row;
    padding: 0 rem(20);

    span {
      color: currentColor;
      display: none;
      font-size: rem(16);
      margin-right: rem(20);
    }

    svg {
      height: 2rem;
      width: 2rem;
    }
  }
}

@include media-breakpoint-down(md) {
  .up-button {
    height: rem(35);

    .up-button-content {
      height: rem(35);
      padding: 0 rem(3);
    }
  }
}

@include media-breakpoint-up(md) {
  .up-button {
    margin-right: 3.2rem;

    .up-button-content span {
      display: inline;
    }
  }
}

@include media-breakpoint-up(lg) {
  .up-button {
    margin-right: 5.5%;
  }
}

@include media-breakpoint-up(xl) {
  .up-button {
    margin-right: 8.5%;
  }
}

@include media-breakpoint-up(xxl) {
  .up-button {
    margin-right: calc((100vw - #{rem(1614)}) / 2);
  }
}

.critical-post-info-checkbox {
  left: -1.25rem;
  position: relative;
}
