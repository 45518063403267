@use '../../functions' as *;

.desknet-toggle-buttons {
  .btn {
    background-color: $color-milk;
    border: 1px solid $color-graphite;
    color: $color-graphite;
    padding: rem(4) rem(12);
    position: relative;

    &:first-of-type {
      margin-right: rem(-5);
    }

    &:hover {
      cursor: pointer;
    }
  }

  .btn-check:checked + .btn {
    background-color: $color-white;
    border-color: $color-granite;
    color: $color-black;
    z-index: 2;
  }
}

.desknet-topic {
  @include media-breakpoint-up(sm) {
    .w-auto {
      max-width: 80%;
    }
  }

  @include media-breakpoint-down(sm) {
    >.row >* {
      padding: 0;
    }

    .desknet-publication {
      .text-truncate {
        font-weight: bold;
      }
    }
  }

  .video-badge svg {
    margin-top: rem(-1);
    scale: 0.8;
    -webkit-transform-origin-x: center;
    -webkit-transform-origin-y: top;
  }
}
