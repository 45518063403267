@use '../../functions' as *;

.modal {
  .modal-dialog.modal-wide {
    margin: 0 var(--divider-thin);
    max-width: unset;

    @include media-breakpoint-up(lg) {
      margin: 0 auto;
      max-width: 80%;
    }
  }

  .modal-dialog.modal-medium {
    max-width: rem(600);
  }

  .modal-content {
    max-height: 75vh;
    overflow: auto;

    @include media-breakpoint-up(md) {
      max-height: 75vmin;
    }
  }

  .modal-body {
    padding: var(--divider-medium) var(--bs-modal-padding);
  }

  .modal-title {
    font-size: rem(21);
    margin-top: 0;
  }

  .modal-label {
    color: $color-granite;
  }

  textarea ~ .input-counter {
    margin-bottom: rem(-14);
  }
}
