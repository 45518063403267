@use '../functions' as *;

$input-height: rem(50);
$panel-spacing: rem(40);
$input-min-width: rem(160);
$input-max-width: rem(400);

@mixin action-button {
  .sbb-button.action-button {
    background-color: $color-granite;
    border: 1px solid $color-granite;
    border-radius: rem(2);
    color: $color-iron;
    display: inline-block;
    height: $input-height;
    line-height: $input-height;
    max-width: $input-max-width;
    min-width: $input-min-width;
    outline: 0;
    padding: 0 rem(10);
    text-align: center;
    vertical-align: bottom;

    &,
    * {
      text-decoration: none;
    }

    &:hover {
      cursor: not-allowed;
    }

    &:not([disabled]) {
      @include action-arrows(span, rem(30));
      background-color: $color-red;
      border: 0;

      &,
      * {
        color: $color-white;
        text-decoration: none;
      }


      &:hover {
        background-color: $color-red125;
        cursor: pointer;
      }

      span {
        line-height: $input-height;

        &::before,
        &::after {
          font-size: rem(24);
          top: calc(50% - #{rem(12)});
        }
      }
    }
  }
}
