@use '../../functions' as *;

@keyframes open {
  from {
    clip-path: inset(0 0 100% 0);
  }

  to {
    clip-path: inset(0 0 0 0);
  }
}

.dropdown {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
  }

  &__button {
    background-color: $color-red;
    border: 0;
    border-radius: rem(2);
    color: $color-white;
    display: flex;
    height: rem(48);
    line-height: 1;
    padding: rem(16) rem(16);
    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
    }

    &.show .arrow-icon {
      transform: rotate(-180deg);
      transition: 0.3s ease-in-out;
    }

    &::after {
      border: 0;
      content: '';
      margin: 0;
    }

    &:hover,
    &.show {
      background-color: $color-red125;
    }

    .arrow-icon {
      height: rem(18);
      transform: rotate(0deg);
      transition: 0.3s ease-in-out;

      polyline {
        stroke: $color-white;
      }
    }
  }

  &__menu {
    border-top: 0;
    box-shadow: 0 rem(4) 0 rgba($color-black, 0.15);
    width: 100%;

    &.show {
      animation: open 0.25s ease-out;
    }
  }

  &__item {
    border-left: 1px solid transparent;

    &:hover {
      border-left: 1px solid $color-red125;
    }

    &.disabled {
      background-color: $color-cloud;
      opacity: 0.4;
    }
  }
}

.dropdown-inline {
  button:not(.collapsed) .arrow-icon {
    transform: rotate(180deg);
    transition: 0.3s ease-in-out;
  }

  .arrow-icon {
    transition: 0.3s ease-in-out;
  }

  li {
    margin-left: rem(38);
  }

  .option-count {
    background: $color-cloud;
    color: $color-anthracite;
    font-size: 0.825rem;
    font-weight: bold;
    height: rem(30);
    width: rem(30);
  }
}
