/*stylelint-disable selector-class-pattern*/
@use '../../functions' as *;

.sbb-form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--divider-small);
  scroll-margin-top: calc(var(--header-height) + var(--divider-small));

  &.-has-error {
    .sbb-input {
      border-color: $color-red125;
    }
  }

  .error-wrapper {
    font-size: rem(13);

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
      }
    }
  }
}

.sbb-label {
  color: $color-granite;
  font-size: rem(13);
  line-height: rem(16);
  margin-bottom: rem(4);
}

.sidebar-anchor-links-overview {
  .anchor-link {
    color: #767676;
  }

  .anchor-link.active::before {
    content: '|';
    left: -1.5625rem;
    position: absolute;
  }
}

.remove-bold {
  font-weight: normal;
}

.small-width {
  width: 100%;
}

.list-bg {
  background-color: $color-white;
}

.inactive-link {
  color: #222;
}

.hotlinks-border-color {
  border-color: #dcdcdc;
}

.scrolling-position {
  scroll-margin-top: 3.75rem;
}

.inactive-link {
  color: #222;
}

.anchor-link.active {
  color: #000;
  font-weight: bold;
}

.sbb-input {
  border: 1px solid $color-graphite;
  border-radius: rem(2);
  color: $color-granite;
  min-height: rem(37);
  padding: rem(4) rem(8);

  &::placeholder {
    color: $color-storm;
  }

  &:focus,
  &:focus-visible {
    border-color: $color-iron;
    outline: none;
  }
}

.sbb-textarea {
  margin-bottom: rem(-8);
}

.sbb-checkbox {
  height: rem(20);
  left: 0;
  opacity: 0;
  position: absolute;
  top: rem(3);
  width: rem(20);
  z-index: z-index(page-layout, checkbox-input);

  &:checked + .sbb-checkbox-label {
    color: $color-iron;

    .icon {
      visibility: visible;
    }
  }

  &:disabled + .sbb-checkbox-label {
    color: $color-storm;

    &::before {
      background-color: $color-milk;
      border-color: $color-aluminium;
    }
  }

  &[readonly] {
    @extend :disabled;
    pointer-events: none;
  }

  &[readonly] + .sbb-checkbox-label {
    pointer-events: none;
  }

  &:focus + .sbb-checkbox-label,
  &:focus-visible + .sbb-checkbox-label {
    &::before {
      border-color: $color-iron;
    }
  }
}

.sbb-select,
.ts-control {
  &::after {
    display: none;
  }

  .clear-button {
    color: transparent;
    font-size: 0.0625rem;

    &::after {
      background-image: url('/public/icons/cross-white.svg');
      background-size: cover;
      content: '';
      margin-left: 1rem;
      position: absolute;
    }
  }

  .clear-button,
  .clear-button::after {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.sbb-checkbox-label {
  align-items: center;
  color: $color-granite;
  display: flex;
  margin: 0;
  padding: 0;
  position: relative;

  &::before {
    border: 1px solid $color-graphite;
    border-radius: rem(2);
    content: '';
    display: inline-block;
    height: rem(20);
    margin-right: rem(4);
    min-width: rem(20);
    position: relative;
    width: rem(20);
    z-index: z-index(page-layout, checkbox-box);
  }

  .icon {
    left: rem(-2);
    position: absolute;
    visibility: hidden;
    z-index: z-index(page-layout, checkbox-tick);
  }
}

.sbb-radiobutton {
  height: rem(20);
  left: 0;
  opacity: 0;
  position: absolute;
  top: rem(3);
  width: rem(20);
  z-index: z-index(page-layout, checkbox-tick);

  &:checked + .sbb-radiobutton-label {
    color: $color-iron;

    .icon {
      visibility: visible;
    }
  }

  &:disabled + .sbb-radiobutton-label {
    color: $color-storm;

    &::before {
      background-color: $color-milk;
      border-color: $color-aluminium;
    }
  }

  &:focus + .sbb-radiobutton-label,
  &:focus-visible + .sbb-radiobutton-label {
    &::before {
      border-color: $color-iron;
    }
  }
}

.sbb-radiobutton-label {
  align-items: center;
  color: $color-granite;
  display: flex;
  margin: 0;
  padding: 0;
  position: relative;

  &::before {
    border: 1px solid $color-graphite;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: rem(20);
    margin-right: rem(4);
    min-width: rem(20);
    position: relative;
    width: rem(20);
    z-index: z-index(page-layout, checkbox-box);
  }

  .icon {
    background-color: $color-granite;
    height: rem(14);
    left: rem(3);
    position: absolute;
    visibility: hidden;
    width: rem(14);
    z-index: z-index(page-layout, checkbox-tick);
  }
}

.sbb-number {
  width: fit-content;
}

.input-counter {
  bottom: 0;
  color: $color-granite;
  font-size: rem(13);
  line-height: rem(13);
  margin-bottom: rem(-14);
  margin-right: var(--divider-thin);
  position: absolute;
  right: 0;
  z-index: z-index(sidebar, counter);
}

textarea {
  min-height: rem(100);
}

.radio-wrapper {
  width: fit-content;
}

.dropzone-container {
  background-color: $color-cloud;
  border-color: $color-smoke;
  border-radius: rem(5);
  justify-content: center;

  button {
    background-color: $color-granite;
    color: $color-white;

    &:hover {
      background-color: $color-iron;
    }
  }

  .dropzone-placeholder {
    color: $color-granite;
    display: flex;
    flex-direction: column;
    padding-bottom: rem(60);
    padding-top: rem(60);

    .dropzone-placeholder-button {
      margin: auto;
      width: fit-content;
    }
  }

  .dropzone-preview {
    align-items: flex-start;
    position: relative;
    width: 100%;

    .dropzone-preview-filename {
      margin-left: rem(5);
      margin-top: rem(-5);
    }

    .dropzone-preview-image {
      background-position: right;
      height: rem(200);
      max-width: none;
      min-width: 50%;
    }

    .dropzone-preview-button {
      align-items: center;
      display: flex;
      height: fit-content;
      left: 50%;
      margin-left: rem(15);
      position: absolute;
      top: calc(100% - #{rem(40)});
      width: fit-content;

      img {
        margin-left: rem(16);
      }

      &::before {
        content: none;
      }
    }
  }
}
