@use '../../functions' as *;

.sbb-toggle {
  display: flex;
  margin-bottom: var(--divider-thin);

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }

  // Bring the hover, focused, and "active" buttons to the front to overlay
  // the borders properly
  > .btn-check:checked + .btn,
  > .btn-check:focus + .btn,
  > .btn:hover,
  > .btn:focus,
  > .btn:active,
  > .btn.active {
    z-index: 1;
  }
}

.sbb-toggle-btn {
  background-color: $color-milk;
  border: rem(1) solid $color-silver;
  border-radius: rem(2);
  color: $color-aluminium;
  cursor: pointer;
  font-weight: normal;
  line-height: 1rem;
  padding: rem(11) rem(16);
  text-align: center;
  width: 100%;

  @include media-breakpoint-up(md) {
    text-align: left;
    width: auto;
  }

  &:first-of-type {
    border-bottom-right-radius: 0;
    border-right-color: rgba(0, 0, 0, 0);
    border-top-right-radius: 0;
  }

  &:last-of-type {
    border-bottom-left-radius: 0;
    border-left-color: rgba(0, 0, 0, 0);
    border-top-left-radius: 0;
  }

  .btn-check:focus-visible + & {
    outline: 0;
  }

  .btn-check:checked + &,
  :not(.btn-check) + &:active,
  &:first-child:active,
  &.active,
  &.show {
    background-color: $color-white;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: $color-black;
    border-radius: rem(3);
    color: $color-black;
  }

  &.disabled {
    cursor: default;
    opacity: 0.4;
  }

  &:disabled {
    cursor: default;
    opacity: 0.4;
  }
}
